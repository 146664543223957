$mt-main-bg: #F7FAFC;
$mt-text-error: #E13131;
$mt-text-warn: #7f6003;
$mt-emphasis-bg: rgba(63, 81, 181, 0.2);

.mt-main-bg {
  background-color: $mt-main-bg;
}

.mt-required-field::after {
  color: $mt-text-error;
  content: ' *';
}


.mt-no-avatar {
  border-radius: 50%;
  background-color: #9E9E9E;
}

.mt-alert-icon {
  background-color: rgba(243, 68, 67, 0.15);
  color: #F34443;
  border-radius: 50%;
}

.mt-alert-menuitem .p-menuitem-text, .mt-alert-menuitem .p-menuitem-icon {
  color: #F34443 !important;
}

.mt-filter-link {
  color: var(--gray-600);
  text-decoration: none;
  cursor: pointer;
  transition: .3s filter linear;

  &:hover {
    filter: brightness(80%);
  }
}


.mt-link {
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
  transition: .3s filter linear;

  &:hover {
    filter: brightness(80%);
  }
}


.mt-chip-container {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.mt-chip {
  display: inline-block;
  align-items: center;
  background-color: rgba(63, 81, 181, 0.12);
  font-size: .8rem;
  white-space: nowrap;
  border-radius: 10px;
  padding: 1px 7px;
  text-decoration: none;

  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 200px;

}

.mt-chip:not(:last-child) {
  margin-right: 3px;
}

.mt-cell-right-border {
  border-right: 1px solid #e4e4e4 !important;
}

.mt-last-left-frozen-column {
  border-right: 1px solid #e4e4e4 !important;
}

.mt-first-right-frozen-column {
  border-left: 1px solid #e4e4e4 !important;
}

p-columnfilterformelement p-multiselect .p-multiselect {
  min-width: 300px;
}

.mt-pct-box {
  background-color: var(--surface-200);
  border: 1px solid var(--surface-300);
  border-radius: 3px;
  padding: 0 .2rem;
  min-width: 40px;
  text-align: center;
}

.p-overlaypanel {
  background: #FFFFFD;

  &:after {
    border-bottom-color: #FFFFFD;
  }
}

p-table, p-treeTable {
  &:not(.mt-no-sticky-paginator) p-paginator {
    position: sticky;
    bottom: 0;
    z-index: 2;
    > .p-paginator {
       border-top: 1px solid #D5DFE6 !important;
    }
  }
}


.mt-transparent {
  .p-tabview .p-tabview-nav,
  .p-tabview .p-tabview-panels,
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background-color: transparent;
  }
}

.p-dialog.mt-edit-dialog .p-dialog-content {
  background-color: $mt-main-bg;
  border-top: 1px solid var(--gray-200);
  border-bottom: 1px solid var(--gray-200);
  padding-top: 1rem;
}


.mt-text-error {
  color:  $mt-text-error;
}

.mt-text-warn {
  color:  $mt-text-warn;
}

.mt-error-message {
  color:  $mt-text-error;
  font-size: .8rem;
}

.mt-draft-entity .mt-error-message > .mt-required {
  display: none;
}

.mt-show-all-chips .p-multiselect .p-multiselect-label {
  white-space: normal;
}


.mt-table-container {
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}

.mt-edit-cursor {
  cursor: url("../images/edit.cur") 16 16, text;
}

.mt-emphasis-bg {
  background-color: $mt-emphasis-bg;
}
