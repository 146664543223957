$bt-spacing: 8px;
$bt-cell-padding: 10px;
$bt-border-color: #D5DFE6;
$bt-frozen-border-color: #C8C8C8;
$bt-section-bg: #E3EAEF;
$bt-section-with-children-bg: #F1F5F8;
$bt-hover-border-color: #009BFF;
$bt-current-period-bg: #D2FFD2; // #e0ffff;

p-treetable.mt-budget-table .p-treetable-tbody > tr > td {
  padding: $bt-cell-padding;
}

p-treetable.mt-budget-table {
  th, td {
    position: relative;
  }
  table:not(.p-treetable-scrollable-header-table) {
    border-collapse: separate !important;
    border-spacing: 0 $bt-spacing;
  }

  table td:not(:last-child) {
    border-right-width: 1px;
  }

  .p-treetable-frozen-view tr:not(.mt-extra-row) td:last-child {
    border-right: 1px solid $bt-frozen-border-color !important;
  }

  table th:not(:last-child) {
    border-right-width: 1px;
  }

  .p-treetable-frozen-view th:last-child {
    border-right: 1px solid $bt-frozen-border-color !important;
  }

  .p-treetable-thead > tr > th,
  .p-treetable-tbody > tr > td {
    border-color: $bt-border-color;
  }

  td.mt-value-cell {
    cursor: pointer;
  }

  .mt-popup-cell, .mt-popup-cell-shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }


  .mt-popup-cell {
    background-color: #FFF;
    padding: 0 $bt-cell-padding;
    z-index: 2;
    border: 0 solid $bt-border-color;
    border-width: 1px 0 0 0;
    font-size: 1rem;
    font-weight: 500;
  }

  &:not(.mt-planning-table) .mt-popup-cell {
    @media (max-width: 1700px) {
      font-size: .875rem;
      font-weight: bold;
    }
  }

  .mt-popup-cell-shadow {
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%),
    0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%);
    z-index: 1;
  }


  tr.mt-period-group {
    th {
      background: $bt-section-bg;
      position: relative;
      > .mt-period-group-header-bottom {
        position: absolute;
        bottom: -($bt-spacing * .5) - 1px;
        left: 0;
        right: 0;
        height: $bt-spacing * .5 + 1px;
        background-color: $bt-section-bg;
        border: 0 solid #D5DFE6;
        border-bottom-width: 1px;
      }
      &:not(:last-child) > .mt-period-group-header-bottom {
        right: -1px;
        border-right-width: 1px;
      }

    }
  }

  tr.mt-period-header {
    th {
      background: $bt-section-with-children-bg;
      position: relative;
      &.mt-period-current {
        background-color: $bt-current-period-bg;
        > .mt-period-header-top, .mt-popup-cell {
          background-color: $bt-current-period-bg;
        }
      }
      > .mt-period-header-top {
        position: absolute;
        top: -($bt-spacing * .5);
        left: 0;
        right: 0;
        height: $bt-spacing * .5;
        background-color: $bt-section-with-children-bg;
        border: 0 solid $bt-border-color;
      }
      &:not(:last-child) > .mt-period-header-top {
        right: -1px;
        border-right-width: 1px;
      }
      .mt-popup-cell {
        background: $bt-section-with-children-bg;
        top: -($bt-spacing * .5);
      }
      .mt-popup-cell-shadow {
        top: -($bt-spacing * .5);
      }
    }
  }

  .p-treetable-scrollable-header-table tr.mt-period-header th {
    .mt-popup-cell {
      top: 0;
    }
    .mt-popup-cell-shadow {
      top: 0;
    }

  }

  tr.mt-section-row > td,
  tr.mt-section-row:not(.mt-has-children) > td .mt-popup-cell {
    background: $bt-section-bg;
  }

  tr.mt-section-row.mt-has-children > td,
  tr.mt-section-row.mt-has-children > td .mt-popup-cell {
    background: $bt-section-with-children-bg;
  }

  tr:not(.mt-section-row) > td {
    padding: $bt-cell-padding - $bt-spacing $bt-cell-padding $bt-cell-padding;
    > .mt-nested-row-cell-top {
      position: absolute;
      top: -$bt-spacing;
      left: 0;
      right: 0;
      height: $bt-spacing;
      background-color: #FFF;
      border: 0 solid $bt-border-color;
    }
    &:not(:last-child) > .mt-nested-row-cell-top {
      right: -1px;
      border-width: 0 1px 0 0;
    }
    .mt-popup-cell,
    .mt-popup-cell-shadow {
      top: -$bt-spacing - 1;
    }
  }

  .p-treetable-frozen-view tr:not(.mt-section-row) > td:last-child  > .mt-nested-row-cell-top {
    right: -1px;
    border-right-width: 1px;
    border-right-color: $bt-frozen-border-color;
  }

  td.mt-value-cell:not(.mt-has-popup-cell):hover {
    border: 1px solid $bt-hover-border-color;
    > .mt-nested-row-cell-top {
      border-color: $bt-hover-border-color;
      border-width: 1px 1px 0 1px;
      left: -1px;
      top: -$bt-spacing - 1;
      height: $bt-spacing + 1;
    }
    &:last-child > .mt-nested-row-cell-top {
      right: -1px;
    }
  }

  td.mt-value-cell .mt-popup-cell:hover {
    border: 1px solid $bt-hover-border-color;
  }

  .p-treetable-scrollable-header {
    background: transparent;
  }

  th.mt-hidden-header {
    visibility: hidden;
    border: none;
  }

  table.p-treetable-scrollable-header-table {
    margin-bottom: $bt-spacing;
  }

  .p-treetable-scrollable-body > table {
    margin-top: -$bt-spacing;
  }

}

p-treetable.mt-budget-table {
  .data-row {
  }
}

p-treetable.mt-budget-table.mt-planning-table {
  .name-column {
    width: 300px;
  }

  th, td {
    font-size: 12px;
  }

  .data-row {
    height: 50px - $bt-spacing; // sectionRowH - spacing
  }

  .data-row.mt-section-row {
    height: 50px;
  }

  .mt-summary-row {
  }

  .mt-summary-row td {
    background-color: #FFF;
  }

  .mt-summary-item {
    padding: .15rem;
  }

  .mt-extra-row {
    background-color: transparent;
    td {
      border: none;
      background-color: transparent;
    }
  }


}
