@import "jsoneditor/dist/jsoneditor.css";

.jsoneditor {
  border: none;
}

.jsoneditor-menu {
  border: none;
  background-color: var(--surface-500);
  button {
    opacity: 1;
    transition: background-color .3s;
    &:hover {
      border: none;
      border: 1px solid transparent;
    }
  }
  .jsoneditor-search {
    .jsoneditor-results {
      font-family: Roboto;
    }
    .jsoneditor-frame {
      border-radius: 3px;
    }
  }
}

.jsoneditor-navigation-bar {
  background: #e4e4e4;
  border-bottom: 1px solid var(--surface-400);
  height: auto;
  padding: 3px 0;
  color: var(--text-color);
  font-family: Roboto;
  font-size: 11pt;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .jsoneditor-treepath-element {
    font-family: Roboto;
    font-size: 11pt;
  }
}

div.jsoneditor-value.jsoneditor-string {
  color: #008000;
  font-weight: bold;
}

div.jsoneditor-value.jsoneditor-number {
  color: #0000FF;
}

div.jsoneditor-value.jsoneditor-boolean {
  color: #000080;
  font-weight: bold;
}

div.jsoneditor-value.jsoneditor-null {
  color: #006000;
  font-weight: bold;
}


div.jsoneditor-value.jsoneditor-object, div.jsoneditor-value.jsoneditor-array {
  min-width: max-content;
}
div.jsoneditor-field, div.jsoneditor-value, div.jsoneditor-readonly, div.jsoneditor-default {
  min-width: max-content;
}


.jsoneditor-treepath span.jsoneditor-treepath-element:hover,
.jsoneditor-treepath span.jsoneditor-treepath-seperator:hover {
  text-decoration: none;
  color: var(--primary-color);
  transition: color .3s;
}

.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected,
.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected:hover,
.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected:focus {
  background-color: inherit; // var(--surface-600);
  color: var(--text-color);
  font-weight: 500;
}
.jsoneditor-contextmenu .jsoneditor-menu button {
  width: 100%;
  padding: 0;
  font-size: 11pt;
  font-family: Roboto;
  transition: background-color .3s;
}
.jsoneditor-contextmenu .jsoneditor-text {
  padding: 8px;
  word-wrap: normal;
}

.jsoneditor-menu button:hover {
  border: none;
}

.jsoneditor-mode-view > .jsoneditor-menu {
  display: none;
}

div.jsoneditor-outer.has-nav-bar.has-main-menu-bar {
  margin-top: 0;
  padding-top: 0;
}

.jsoneditor-contextmenu .jsoneditor-menu {
  width: auto;
}
