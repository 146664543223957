@import "override/json-editor";
@import "override/prime.scss";
@import "components/main-page";
@import "components/misc";
@import "components/budget-table";

html {
  font-family: Roboto;
  font-size: 12px;
}

body {
  margin: 0;
  background-color: #F7FAFC;
}

.mt-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mt-tooltip-on-overflow {
  max-width: 350px;
}

.mt-tooltip-on-overflow .p-tooltip-text {
  background-color: #FFFFFA;
  color: rgba(0, 0, 0, .7);
  overflow-wrap: anywhere;
  border: 1px solid rgba(0, 0, 0, .2);
}

.mt-invalid {
  color: #B00020;
}

fa-icon.mt-disabled {
  opacity: .5;
}

